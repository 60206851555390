<template>
  <div class="container page">
    <van-nav-bar :title="title" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div
      style="width: 96%;margin: 10px auto;height: calc(100vh - 35vw);overflow-x: hidden;overflow-y: auto;background: #fff;padding: 10px;"
    >
      <img :src="data.header_img" style="width: 100%;" />
      <ul style="line-height: 28px;">
        <li style="font-weight: 600;font-size: 18px;margin-bottom: 15px">
          {{ data.nationality }}
        </li>
        <li style="font-weight: 600;">
          Ngành nghề:
        </li>
        <li style="border-bottom: 1px solid #ecedef;">
          {{ data.industry }}
        </li>
        <li style="font-weight: 600;">
          Mức lương :
        </li>
        <li style="border-bottom: 1px solid #ecedef;">
          <div style="overflow-wrap: break-word;">
            {{ data.salary }}
          </div>
        </li>
      </ul>
      <div style="margin-top: 10px;overflow: hidden;width: 100%;">
        <div class="hot-overflow-context-item-icon-txt">
          <div
            class="hot-overflow-context-item-icon"
            style=" min-height: 16px !important;
  width: 16px !important;
  height: 16px !important;
  background-position: -20px -46px;float: left;"
          />
          &nbsp;
          {{ showtiem(data.time) }}
        </div>
        <div class="hot-overflow-context-item-icon-txt" style="float: right;">
          <div
            class="hot-overflow-context-item-icon"
            style=" min-height: 16px !important;
  width: 16px !important;
  height: 16px !important;
  background-position: -45PX -46PX;;float: left;"
          />
          &nbsp;
          {{ data.address }}
        </div>
      </div>
      <div v-html="data.content" style="margin: 10px;"></div>
    </div>
    <van-button class="sign-out" type="primary" size="normal" @click="todo()">Sảnh Bình Chọn</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      data: {
        content: "",
        create_time: "",
        header_img: "",
        id: "",
        industry: "",
        nationality: "",
        salary: "",
        top: "",
        address: "",
      },
      title: "",
    };
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.id = this.$route.query.id;
      this.Init();
    }
  },
  methods: {
    back() {
      return window.history.back();
    },
    Init() {
      this.getData();
    },
    getData() {
      this.hotlst = [];
      this.lst = [];
      this.$http({
        method: "get",
        url: "get_home1_data",
      }).then((res) => {
        res.data.forEach((item) => {
          if (item.id == this.id) {
            this.data = item;
            this.title = item.industry;
          }
        });
      });
    },
    showtiem(val) {
      let result = val;
      switch (val) {
        case 0:
          result = this.$t("timetxt.v0");
          break;
        case 1:
          result = this.$t("timetxt.v1");
          break;
        case 2:
          result = this.$t("timetxt.v2");
          break;
        case 3:
          result = this.$t("timetxt.v3");
          break;
        case 4:
          result = this.$t("timetxt.v4");
          break;
      }
      return result;
    },
    todo(){
        this.$router.push({ path: '/Game' })
    },
  },
};
</script>
<style scoped>
.hot-overflow-context-item-icon {
  background: url("/img/sy/common.png");
  background-repeat: no-repeat;
  margin-top: 2px;
}
.hot-overflow-context-item-icon-txt {
  font-size: 0.8rem;
  color: #717375;
  float: left;
  line-height: 1.6;
}
.sign-out{
  margin: 10px 20px 0;
  height: 100px;
  line-height: 100px;
  border-radius: 50px;
  color: #fff;
  font-size: 40px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(90deg, #500cfd, #9e9ce7);
}
</style>
