<template>
  <div class="mine page">
    <div class="movie-hall page">
      <section id="team" class="team mowpad relative">
        <div class="title">
          <h1 data-v-3734890c="">
            CÔNG TY TNHH ĐẦU TƯ DỊCH VỤ DI TRÚ TOÀN CẦU
          </h1>
        </div>
        <div class="mowtainer">
          <div class="mowgrid">
            <div class="member">
              <div class="image">
                <img src="/img/bs1/a1.jpg" alt="" />
              </div>
            </div>
            <div class="member">
              <div class="image">
                <img src="/img/bs1/a2.jpg" alt="" />
              </div>
            </div>
            <div class="member">
              <div class="image">
                <img src="/img/bs1/a3.jpg" alt="" />
              </div>
            </div>
            <div class="member">
              <div class="image">
                <img src="/img/bs1/a4.jpg" alt="" />
              </div>
            </div>
            <br data-v-3734890c="" />
          </div>
        </div>
        <div
          data-v-78cbd0d8=""
          style="display: flex; flex-direction: column; margin-bottom: 28px;"
        >
          <div style="display: flex;">
            <img
              data-v-78cbd0d8=""
              src="/img/bs1/q1.jpg"
              alt=""
              style="width: 100%;"
            />
          </div>
          <div style="display: flex;">
            <img
              data-v-78cbd0d8=""
              src="/img/bs1/q2.jpg"
              alt=""
              style="width: 100%;"
            />
          </div>
          <div style="display: flex;">
            <img
              data-v-78cbd0d8=""
              src="/img/bs1/2.jpg"
              alt=""
              style="width: 100%;"
            />
          </div>
          <div style="display: flex;">
            <img
              data-v-78cbd0d8=""
              src="/img/bs1/7.jpg"
              alt=""
              style="width: 100%;"
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>

#team {
    color: #fff!important;
    padding: 30PX 0
}

.title {
    margin-bottom: 30PX;
    text-align: center
}

.title h1 {
    font-size: 46PX;
    font-family: Old Fenris,sans-serif;
    text-transform: uppercase;
    letter-spacing: 3PX;
    text-shadow: 0 6PX 8PX #000;
    font-size: 40PX;
    margin-bottom: 20PX
}

.mowtainer {
    width: 90%;
    margin: 0 auto
}

.team .mowgrid {
    grid-template-columns: repeat(auto-fill,minmax(130PX,1fr));
    grid-gap: 40PX 30PX;
    display: grid
}

.team .member .image {
    border-radius: 50%;
    border: 6PX ridge #bca05c;
    position: relative;
    z-index: 2;
    box-shadow: 0 0 15PX #000
}

.team .member img {
    width: 100%;
    height: 159PX;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    vertical-align: middle
}

.team {
    background: #9fdfe9;
    background-image: url(/img/bs/2.webp);
    background-size: cover;
    z-index: 1
}

.page {
    position: absolute!important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f5
}

.bg-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(hsla(0,0%,100%,0),#500cfd)
}

.mine {
    position: relative;
    bottom: 1.333vw;
    background: #f2f2f5
}

.mine .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.nav-bar {
    background: none
}

.mine .header {
    background: none;
    padding-bottom: 13.333vw
}

 .van-nav-bar__content {
    height: 13.333vw
}

 .van-hairline--bottom:after {
    border-bottom-width: 0
}

.mine .header .van-nav-bar .van-icon {
    font-size: 6vw
}

.mine .header .user-wrapper {
    display: flex;
    align-items: center;
    margin: 0 5.333vw 0 5.333vw
}

.mine .user_img {
    height: 17.333vw;
    width: 17.333vw
}

 .van-loading__spinner {
    height: 6.667vw;
    width: 6.667vw
}

 .van-image__error-icon {
    font-size: 9.333vw
}

.mine .header .user-wrapper .login-content {
    flex: 1;
    margin-left: 4vw
}

.mine .header .user-wrapper .login-content .login-btn {
    display: inline-block;
    font-size: 5.333vw;
    line-height: 0;
    color: #fff
}

.mine .header .user-wrapper .login-content .login-label {
    margin-top: -1.733vw;
    font-size: 3.733vw;
    color: hsla(0,0%,100%,.6)
}

.mine .page-bg {
    height: 66.667vw;
    background: linear-gradient(90deg,#500cfd,#9e9ce7);
    z-index: 0;
    background-size: cover;
    position: relative
}

.mine .content {
    position: relative;
    padding: 1.333vw 4vw 4vw;
    min-height: 66.667vw;
    background-color: #f2f2f5
}

 .van-pull-refresh__track .van-pull-refresh__head * {
    color: #fff;
    font-size: 4.667vw
}

.mine .wrapper .content .finance {
    position: absolute;
    display: flex;
    align-items: center;
    top: -7.333vw;
    left: 4vw;
    right: 4vw;
    height: 16vw;
    background-color: #fff;
    border-radius: 2vw;
    box-shadow: 0 .2vw 1px 0 #e4e4e7
}

.mine .wrapper .content .finance .line {
    width: .4vw;
    height: 5.333vw;
    background-color: #ccc
}

.mine .wrapper .content .finance .finance-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%
}

.mine .wrapper .content .finance .finance-item .text {
    margin-left: 4vw;
    font-size: 4vw;
    color: #000;
    font-weight: 500
}

.mine .wrapper .content .finance .finance-item .icon {
    font-size: 6.667vw
}

.mine .wrapper .content .menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: #fff;
    border-radius: 2vw;
    box-shadow: 0 .2vw 1px 0 #e4e4e7
}

.mine .wrapper .content .menu .menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    height: 17.333vw
}

.mine .wrapper .content .menu .menu-item .menu-item-label {
    font-size: 4vw;
    color: #868686;
    font-weight: 500
}

.mine .wrapper .content .menu .menu-item .menu-item-icon {
    margin: 3.333vw;
    width: 8vw;
    height: 8vw;
    -o-object-fit: contain;
    object-fit: contain
}

.mine .wrapper .content .wallet {
    margin-top: 10.667vw;
    padding: 0 4vw;
    background-color: #fff;
    border-radius: 2vw;
    box-shadow: 0 .2vw 1px 0 #e4e4e7
}

.mine .wrapper .content .wallet .part-1 {
    display: flex;
    align-items: center;
    height: 13.333vw
}

.mine .wrapper .content .wallet .font-primary-color {
    color: #000
}

.font-gray {
    color: #868686
}

.mine .wrapper .content .wallet .part-2 {
    display: flex;
    align-items: center;
    height: 20vw
}

.mine .wrapper .content .wallet .part-2 .balance {
    flex: 1;
    font-size: 8vw;
    color: #f487e0;
    font-weight: 700
}

.mine .wrapper .content .wallet .van-hairline--bottom:after {
    border-bottom-width: .4vw
}

.mine .wrapper .content .wallet .part-2 .refresh-btn {
    margin-left: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.667vw;
    height: 6.667vw;
    font-size: 4vw;
    border-radius: 50%;
    color: #fff;
    background-color: #e6c3a1
}
</style>
