<template>
  <div class="convention-hall page">
        <div class="nav-bar van-nav-bar van-hairline--bottom">
          <div class="van-nav-bar__content">
            <div class="van-nav-bar__title van-ellipsis">Nước ngoài</div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="left-home col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <div class="pageone">
                <div class="pageone-title" style="margin-bottom: -10px">
                  <p class="line-do"></p>
                </div>
                <div class="pageone-tab">
                  <ul role="tablist" class="nav nav-tabs" style="  margin-top: 1PX;">
                    <li class="active" style="background: rgb(245, 130, 32); height: 32px">
                      <a href="javascript:void(0);" role="tab" data-toggle="tab">Tuyển sinh </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" role="tab" data-toggle="tab" style="height: 32px">Du học Úc   </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" role="tab" data-toggle="tab" style="height: 32px">Du học Mỹ </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" role="tab" data-toggle="tab" style="height: 32px">Đơn hàng cho nữ</a>
                    </li>
                  </ul>
                  <div id="style-2" class="tab-content">
                    <div id="thong-so-ki-thuat" class="tab-pane active">
                      <table class="donhang-home">
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Trường  </td>
                            <td>Quốc Gia</td>
                            <td>Giới tính</td>
                            <td>Số lượng</td>
                          </tr>
                          <tr>
                            <td>
                              2
                            </td>
                            <td>Camberian College</td>
                            <td>Cannada</td>
                            <td>Nam/nữ</td>
                            <td>30</td>
                          </tr>
                          <tr>
                            <td>
                              3
                            </td>
                            <td>Douglas College</td>
                            <td>Canada</td>
                            <td>Nam/Nữ</td>
                            <td>24</td>
                          </tr>
                          <tr>
                            <td>
                              4
                            </td>
                            <td>Saskatchewan polytechnic</td>
                            <td>Canada</td>
                            <td>Nam/Nữ</td>
                            <td>100</td>
                          </tr>
                          <tr>
                            <td>
                             5
                            </td>
                            <td>Deakin University</td>
                            <td>Úc</td>
                            <td>Nữ</td>
                            <td>18</td>
                          </tr>
                          <tr>
                            <td>
                              6
                            </td>
                            <td>Uwa College</td>
                            <td>Úc </td>
                            <td>Nữ</td>
                            <td>30</td>
                          </tr>
                          <tr>
                            <td>
                              7
                            </td>
                            <td>JMC Áutralia</td>
                            <td>Úc</td>
                            <td>Nam/ Nữ</td>
                            <td>120</td>
                          </tr>
                          <tr>
                            <td>
                             8
                            </td>
                            <td>Gerge máon Univesrity</td>
                            <td>Mỹ</td>
                            <td>Nam</td>
                            <td>54</td>
                          </tr>
                          <tr>
                            <td>
                             9
                            </td>
                            <td>lllinois State University </td>
                            <td>Nữ</td>
                            <td>Mỹ</td>
                            <td>Nữ</td>
                          </tr>
                          <tr>
                            <td>
                              10
                            </td>
                            <td>Oregon State Univesity</td>
                            <td>Mỹ </td>
                            <td>Nữ</td>
                            <td>54</td>
                          </tr>
                          <tr>
                            <td>
                              11
                            </td>
                            <td>Saint Louis University  </td>
                            <td>Mỹ</td>
                            <td>Nam</td>
                            <td>20</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="xemthemhome">
                        <a href="javascript:void(0);" title="Xem thêm">Xem thêm</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget widget-highlight-job pagetow">
                <h2 class="widget-title">
                  <a href="javascript:void(0);" title="Việc làm ngoài nước mới nhất">Việc làm ngoài nước mới nhất
                    <font style="
                        float: right;
                        color: red;
                        text-transform: none;
                        font-size: 13px;
                        margin-right: 10px;
                        text-decoration: underline;
                      ">Xem thêm</font></a>
                </h2>
                <div class="clearfix"></div>
                <div class="widget-wrap scrollbar">
                  <div class="widget-content">
                    <ul>
                      <li>
                        <a href="javascript:void(0);" title="Tuyển sinh du học nghề Úc vừa học vừa làm cơ hội kiếm 4000 UAD"><h3>
                            Tuyển sinh du học nghề Úc vừa học vừa làm cơ hội
                            kiếm 4000 UAD
                          </h3>
                          <h4>
                            <span>Mức lương:</span>4000 AUD
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>Úc
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="41 đơn hàng Singapore mới nhất tuyển liên tục"><h3>
                            41 đơn hàng Singapore mới nhất tuyển liên tục
                          </h3>
                          <h4>
                            <span>Mức lương:</span>40000
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>Singapore
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Tuyển sinh du học Úc không cần chứng chỉ tiếng anh Ielts"><h3>
                            Tuyển sinh du học Úc không cần chứng chỉ tiếng anh
                            Ielts
                          </h3>
                          <h4>
                            <span>Mức lương:</span>25 AUD/giờ
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>Úc
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Xuất khẩu lao động Ba Lan tuyển Nam Nữ đóng gói"><h3>
                            Xuất khẩu lao động Ba Lan tuyển Nam Nữ đóng gói
                          </h3>
                          <h4>
                            <span>Mức lương:</span>1.100 USD
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>Ba lan
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Xuất khẩu lao động Ba Lan tuyển nam nữ làm thực phẩm"><h3>
                            Xuất khẩu lao động Ba Lan tuyển nam nữ làm thực phẩm
                          </h3>
                          <h4>
                            <span>Mức lương:</span>1.100 USD
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>Ba lan
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Thông báo chương trình xuất khẩu lao động Đức được nợ phí"><h3>
                            Thông báo chương trình xuất khẩu lao động Đức được
                            nợ phí
                          </h3>
                          <h4>
                            <span>Mức lương:</span>80 triệu
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>CHLB Đức
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Visa E7 Hàn Quốc tuyển thợ cơ khí thợ điện làm việc tại nhà máy đóng tàu"><h3>
                            Visa E7 Hàn Quốc tuyển thợ cơ khí thợ điện làm việc
                            tại nhà máy đóng tàu
                          </h3>
                          <h4>
                            <span>Mức lương:</span>2,7 triệu
                            Won
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>Hàn Quốc
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Đơn hàng xây dựng tại Singapore tuyển 1300 lao động phổ thông"><h3>
                            Đơn hàng xây dựng tại Singapore tuyển 1300 lao động
                            phổ thông
                          </h3>
                          <h4>
                            <span>Mức lương:</span>3000 SGD
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>Singapore
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Thông báo tuyển lao động đi làm việc tại CHLB Đức"><h3>
                            Thông báo tuyển lao động đi làm việc tại CHLB Đức
                          </h3>
                          <h4>
                            <span>Mức lương:</span>2400 Eur
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>Đức
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Xuất khẩu lao động Philippines tuyển nhân viên Sale Marketing phí O đồng"><h3>
                            Xuất khẩu lao động Philippines tuyển nhân viên Sale
                            Marketing phí O đồng
                          </h3>
                          <h4>
                            <span>Mức lương:</span>36 Triệu
                            đồng
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>Philippines
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Xuất khẩu lao động Australia Úc Tuyển 1500 nam nữ làm nông nghiệp"><h3>
                            Xuất khẩu lao động Australia Úc Tuyển 1500 nam nữ
                            làm nông nghiệp
                          </h3>
                          <h4>
                            <span>Mức lương:</span>85 triệu
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>Australia
                            Úc
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Tuyển đầu bếp làm việc tại Đức không yêu cầu tiếng chỉ yêu cầu bảo hiểm"><h3>
                            Tuyển đầu bếp làm việc tại Đức không yêu cầu tiếng
                            chỉ yêu cầu bảo hiểm
                          </h3>
                          <h4>
                            <span>Mức lương:</span>2.800 Euro
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>CHLB Đức
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Xuất khẩu lao động New Zealand đơn hàng tuyển nam nữ phục vụ nhà hàng"><h3>
                            Xuất khẩu lao động New Zealand đơn hàng tuyển nam nữ
                            phục vụ nhà hàng
                          </h3>
                          <h4>
                            <span>Mức lương:</span>80 triệu
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>New Zealand
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Tuyển lao động làm xây dựng tại New Zealand"><h3>
                            Tuyển lao động làm xây dựng tại New Zealand
                          </h3>
                          <h4>
                            <span>Mức lương:</span>4000-
                            6,500 NZD
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>New Zealand
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Ngành nông nghiệp tuyển lao động nam nữ làm farm ở New Zealand"><h3>
                            Ngành nông nghiệp tuyển lao động nam nữ làm farm ở
                            New Zealand
                          </h3>
                          <h4>
                            <span>Mức lương:</span>5000-5500
                            NZ
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>New Zealand
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Tuyển thợ hàn đi Hungary và Slovakia Châu Âu phí rẻ lương cao"><h3>
                            Tuyển thợ hàn đi Hungary và Slovakia Châu Âu phí rẻ
                            lương cao
                          </h3>
                          <h4>
                            <span>Mức lương:</span>2.200 Euro
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>Hungary
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Xuất khẩu lao động Hungary với 5 đơn hàng tuyển mới"><h3>
                            Xuất khẩu lao động Hungary với 5 đơn hàng tuyển mới
                          </h3>
                          <h4>
                            <span>Mức lương:</span>1.100 USD
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>Hungary
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="3 đơn hàng xuất khẩu lao động Hungary làm Phiên dịch, đốc công, điện tử"><h3>
                            3 đơn hàng xuất khẩu lao động Hungary làm Phiên
                            dịch, đốc công, điện tử
                          </h3>
                          <h4>
                            <span>Mức lương:</span>2000USD
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>Hungary
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Xuất khẩu lao động Đài Loan tuyển 15 nam nữ làm sản xuất máy chạy bộ tại Đài Trung"><h3>
                            Xuất khẩu lao động Đài Loan tuyển 15 nam nữ làm sản
                            xuất máy chạy bộ tại Đài Trung
                          </h3>
                          <h4>
                            <span>Mức lương:</span>23.800 Đài
                            tệ
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>Đài Loan
                          </h4></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" title="Tuyển đơn hàng phụ nhà hàng và đầu bếp tại Rumani"><h3>
                            Tuyển đơn hàng phụ nhà hàng và đầu bếp tại Rumani
                          </h3>
                          <h4>
                            <span>Mức lương:</span>1000 USD
                          </h4>
                          <h4>
                            <span>Quốc gia:</span>Rumani
                          </h4></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="clear"></div>
              <div class="xemthemhome" style="margin-top: 80px">
                <a href="javascript:void(0);" title="Xem thêm"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
export default {

}
</script>

<style scoped>

body {
    font-size: 14PX;
    font-family: Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 0
}

.clean {
    clear: both
}

img {
    max-width: 100%;
    height: auto
}

@font-face {
    font-family: menu
}

@font-face {
    font-family: menu_1
}

ul {
    margin: 0;
    padding: 0
}

a:hover {
    text-decoration: none
}

.nav-tabs>li {
    margin-bottom: 0
}

.logo {
    margin-top: 30PX;
    margin-bottom: 20PX
}

.find {
    float: right;
    margin-top: 15PX;
    position: relative;
    border: 1PX solid #cdcdcd;
    max-width: 385PX;
    height: 34PX;
    border-radius: 5PX;
    margin-bottom: 20PX;
    width: 100%
}

.find input[type=text] {
    padding-left: 15PX;
    background: none;
    width: 100%;
    height: 30PX;
    border: none
}

.find input[type=submit] {
    border-radius: 0 5PX 5PX 0;
    background: url(/img/gw/search.png) #008e97 no-repeat 50%;
    width: 60PX;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border: none
}

.hotline-head {
    text-align: right
}

.hotline-head p {
    margin-left: 5PX;
    margin-top: -10PX;
    float: right;
    color: #008e97;
    font-weight: 700;
    font-size: 14PX;
    margin-right: 10PX;
    margin-bottom: 0
}

.hotline-head img {
    float: right
}

#menu {
    background: #008e97
}

.pageone {
    margin-top: 20PX
}

.pageone-title {
    position: relative;
    margin-bottom: 15PX
}

.pageone-title h2 a {
    color: #000
}

.pageone-title h2 {
    font-size: 17PX;
    padding-right: 10PX
}

.line-do {
    margin: 0;
    height: 5PX;
    position: absolute;
    top: 8PX;
    z-index: -1;
    right: 0;
    width: 100%
}

.donhang-home {
    width: 100%;
    border: 1PX solid #cdcdcd;
    font-size: 18px;
}

.donhang-home tr td {
    border: 1PX solid #cdcdcd;
    padding: 7PX 10PX;
    max-width: 200PX
}

.donhang-home tr td a {
    color: #000
}

.donhang-home tr td a:hover {
    color: #008e97
}

.donhang-home tr:first-child td {
    font-weight: 700;
    background: #d5dbfe!important
}

.donhang-home tr td:nth-child(3),.donhang-home tr td:nth-child(5),.donhang-home tr td:nth-child(6) {
    text-align: center
}

.donhang-home tr:nth-child(odd) td {
    background: #f9f9f9
}

.pageone-tab .nav-tabs>li {
    border-left: 1PX solid #cdcdcd;
    width: 25%;
    background: #008e97;
    border-bottom: 2PX solid #fff
}

.pageone-tab .nav-tabs>li:first-child {
    border-left: none
}

.pageone-tab .nav-tabs>li>a {
    color: #fff;
    font-weight: 700
}

.pageone-tab .nav-tabs>li.active>a,.pageone-tab .nav-tabs>li>a:hover {
    margin-right: 0;
    border: none;
    background: #f58220;
    color: #ff0;
    border-radius: 0;
    line-height: 22PX
}

.pagetow {
    margin-top: 25PX
}

.pagetow h2 {
    background: url(/img/gw/bg-titile-L.jpg) no-repeat 0;
    line-height: 42PX;
    width: 100%;
    padding-left: 15PX;
    font-size: 14PX;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0
}

.pagetow h2 a {
    color: #fff
}

.new-pagetow {
    margin-top: 15PX
}

.new-pagetow h3 a {
    color: #f58220
}

.new-pagetow h3 {
    font-size: 18PX;
    padding-top: 25PX;
    padding-bottom: 20PX;
    margin: 0;
    line-height: 23PX
}

.new-pagetowimg {
    width: 100%
}

.date-new {
    font-size: 12PX;
    color: grey;
    margin-bottom: 16PX
}

.date-img {
    margin-left: 10PX
}

.motat-line {
    line-height: 23PX
}

.newtow-pagetow h3 {
    font-size: 14PX;
    margin-top: 0;
    font-weight: 700;
    line-height: 20PX
}

.newtow-pagetow h3 a {
    color: #000
}

.newtow-pagetow li {
    list-style: none;
    margin-top: 15PX;
    overflow: hidden;
    margin-bottom: 25PX
}

.newtow-pagetowimg {
    float: left;
    width: 135PX;
    margin-right: 10PX
}

.newthree-home ul li {
    list-style: none;
    border-bottom: 1PX dashed #cdcdcd;
    margin-top: 20PX;
    padding-bottom: 20PX;
    overflow: hidden
}

.newthree-img {
    width: 170PX;
    margin-right: 15PX;
    float: left
}

.newthree-home ul li h3 {
    font-size: 16PX;
    margin-top: 0;
    font-weight: 700
}

.newthree-home ul li h3 a {
    color: #f58220
}

.album-img {
    margin-top: 15PX
}

.banner-home {
    margin-top: 20PX
}

.banner-home img {
    width: 100%
}

.title-righthome {
    background: url(/img/gw/bg-title.jpg) no-repeat 50%;
    background-size: 100%
}

.title-righthome h2 {
    line-height: 40PX;
    text-transform: uppercase;
    font-size: 14PX;
    color: #fff;
    font-weight: 700;
    text-align: center
}

.title-righthome h2 a {
    color: #fff
}

.support-homeone {
    background: url(/img/gw/bg-support.png) #f3f3f3 no-repeat 100% 100%;
    padding: 15PX
}

.support-homeone p {
    background: url(/img/gw/cham.png) no-repeat 0;
    padding-left: 17PX;
    font-weight: 700
}

.support-homeone p span {
    color: #008e97
}

.p-supporthome {
    text-shadow: 2PX 0 0 hsla(0,0%,100%,.7),-2PX 0 0 hsla(0,0%,100%,.75),0 2PX 0 hsla(0,0%,100%,.72),0 -2PX 0 hsla(0,0%,100%,.66),1PX 1PX hsla(0,0%,100%,.77),-1PX -1PX 0 hsla(0,0%,100%,.68),1PX -1PX 0 hsla(0,0%,100%,.75),-1PX 1PX 0 hsla(0,0%,100%,.73)
}

.video-home h3 {
    font-size: 14PX;
    background: url(/img/gw/video.png) no-repeat left top 3PX;
    padding-left: 25PX;
    margin-bottom: 0;
    line-height: 20PX;
    margin-top: 10PX
}

.video-home h3 a {
    color: #000
}

.video-home h3:hover {
    background: url(/img/gw/video-hover.png) no-repeat left top 3PX;
    font-weight: 700
}

.video-home h3:hover a {
    color: #008e97
}

.bs-slider {
    overflow: hidden;
    max-height: 700PX;
    position: relative;
    background: #000;
    margin-top: 15PX
}

.bs-slider:hover {
    cursor: -webkit-grab
}

.bs-slider:active {
    cursor: -webkit-grabbing
}

.bs-slider .bs-slider-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4)
}

.bs-slider>.carousel-inner>.item>a>img,.bs-slider>.carousel-inner>.item>img {
    margin: auto;
    width: 100%!important
}

.fade {
    opacity: 1
}

.fade .item {
    top: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    position: absolute;
    left: 0!important;
    display: block!important;
    transition: opacity 1s ease-in-out
}

.fade .item:first-child {
    top: auto;
    position: relative
}

.fade .item.active {
    opacity: 1;
    z-index: 2;
    transition: opacity 1s ease-in-out
}

.control-round .carousel-control {
    top: 47%;
    opacity: 0;
    width: 18PX;
    height: 45PX;
    z-index: 100;
    color: #fff;
    display: block;
    font-size: 24PX;
    cursor: pointer;
    overflow: hidden;
    line-height: 43PX;
    text-shadow: none;
    position: absolute;
    font-weight: 400;
    background: transparent;
    border-radius: 100PX
}

.control-round:hover .carousel-control {
    opacity: 1
}

.control-round .carousel-control.left {
    left: 65PX
}

.control-round .carousel-control.right {
    right: 65PX
}

.control-round .carousel-control.left:hover,.control-round .carousel-control.right:hover {
    color: #fdfdfd;
    background: rgba(0,0,0,.5);
    border: 0 transparent
}

.control-round .carousel-control.left>span:first-child {
    left: 45%
}

.control-round .carousel-control.right>span:first-child {
    right: 45%
}

.indicators-line>.carousel-indicators {
    right: 45%;
    bottom: 3%;
    left: auto;
    width: 90%;
    height: 20PX;
    font-size: 0;
    overflow-x: auto;
    text-align: right;
    overflow-y: hidden;
    padding-left: 10PX;
    padding-right: 10PX;
    padding-top: 1PX;
    white-space: nowrap
}

.indicators-line>.carousel-indicators li {
    padding: 0;
    width: 10PX;
    height: 10PX;
    border: 1PX solid #9e9e9e;
    text-indent: 0;
    overflow: hidden;
    text-align: left;
    position: relative;
    letter-spacing: 1PX;
    background: #9e9e9e;
    -webkit-font-smoothing: antialiased;
    border-radius: 50%;
    margin-right: 5PX;
    transition: all .5s cubic-bezier(.22,.81,.01,.99);
    z-index: 10;
    cursor: pointer
}

.indicators-line>.carousel-indicators li:last-child {
    margin-right: 0
}

.indicators-line>.carousel-indicators .active {
    margin: 1PX 5PX 1PX 1PX;
    border: 1PX SOLID #17479b;
    background-color: #17479b;
    position: relative;
    transition: background-color .3s ease
}

.indicators-line>.carousel-indicators .active:before {
    transform: scale(.5);
    background-color: #17479b;
    content: "";
    position: absolute;
    left: -1PX;
    top: -1PX;
    width: 15PX;
    height: 15PX;
    border-radius: 50%;
    transition: background-color .3s ease
}

.slide_style_left {
    text-align: left!important
}

.slide_style_right {
    text-align: right!important
}

.slide_style_center {
    text-align: center!important
}

#footer {
    background: #008e97;
    color: #fff;
    padding: 30PX 0 20PX 0;
    margin-top: 30PX
}

.address h2,.trungtamdaotao h2 {
    font-size: 16PX;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 15PX
}

.address p,.trungtamdaotao p {
    line-height: 25PX
}

.trungtamdaotao h3 {
    margin-top: 0;
    font-size: 14PX
}

.trungtamdaotao h3 a {
    color: #fff
}

.footer-after {
    background: #f58220;
    color: #fff;
    padding: 15PX 0 3PX 0
}

.p-footeraf {
    text-align: right
}

.social-footer img {
    margin-right: 7PX
}

.mobile {
    display: none
}

#cate_t {
    overflow: hidden;
    line-height: 50PX;
    margin: 0
}

#cate_t a,#cate_t h3 {
    line-height: 50PX;
    font-size: 14PX;
    color: #000;
    float: left
}

#cate_t h3 {
    margin: 0;
    font-weight: 500
}

#cate_t span {
    line-height: 50PX;
    float: left;
    margin: 0 10PX;
    font-size: 14PX;
    color: #000
}

#cate_t img {
    float: left;
    margin-top: 15PX;
    margin-right: 9PX
}

.mutinew-title h1,.mutinew-title h2 {
    background: url(/img/gw/bg-titile-L.jpg) no-repeat 0;
    line-height: 42PX;
    width: 100%;
    padding-left: 15PX;
    font-size: 14PX;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    color: #fff
}

.mutinew-title a {
    color: #fff
}

.list-news {
    overflow: hidden;
    border-bottom: 1PX dashed #cdcdcd;
    padding-bottom: 20PX;
    margin-top: 20PX
}

.list-news h3 {
    font-size: 16PX;
    margin-top: 0;
    font-weight: 700
}

.list-news h3 a {
    color: #f58220
}

.list-newsimg {
    width: 250PX;
    margin-right: 15PX;
    float: left
}

.cate-chuyenmuc h2 {
    margin-top: 0
}

.cate-chuyenmuc h3 a {
    color: #000
}

.cate-chuyenmuc h3 {
    padding-top: 10PX;
    background: url(/img/gw/ico.png) #f3f3f3 no-repeat left 11PX top 12PX;
    margin: 0;
    font-size: 14PX;
    padding-left: 31PX;
    padding-bottom: 10PX;
    border-bottom: 1PX solid #fff
}

.cate-chuyenmuc h3:hover {
    background: url(/img/gw/ico-hover.png) #f3f3f3 no-repeat left 6PX top 12PX
}

.cate-chuyenmuc h3:hover a {
    color: red
}

.cate-chuyenmuc h4 {
    background: #f3f3f3;
    margin: 0;
    font-size: 14PX;
    padding: 10PX 30PX
}

.cate-chuyenmuc h4 a {
    color: #000
}

.page {
    width: 100%;
    text-align: center;
    margin-bottom: 10PX;
    float: left
}

.page ul {
    margin-right: 10PX;
    text-align: center;
    float: left;
    margin-left: 0;
    width: 100%;
    margin-top: 20PX
}

.page ul li {
    float: left;
    margin-right: 15PX;
    list-style: none;
    padding: 7PX 12PX;
    background: #f7f7f7;
    box-shadow: 0 1PX 2PX 0 #999;
    width: auto!important;
    margin-right: 0!important;
    border-radius: 3PX
}

.page ul li a {
    color: #777;
    font-size: 14PX;
    text-decoration: none
}

.page ul li.current,.page ul li:hover {
    background: #008e97
}

.page ul li.current a,.page ul li:hover a {
    color: #fff!important
}

.detailnew-title {
    border-top: 1PX solid #cdcdcd
}

.detailnew-title h1 {
    font-size: 18PX;
    font-weight: 700
}

.details {
    overflow: hidden;
    margin-top: 10PX;
    margin-bottom: 25PX
}

.list-producttext {
    padding: 15PX
}

.list-producttext h3 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16PX;
    margin-top: 0;
    line-height: 22PX
}

.list-producttext h3 a {
    color: #008e97
}

.list-producttext p {
    overflow: hidden;
    margin-bottom: 5PX;
    line-height: 21PX;
    clear: both
}

.list-producttext p span {
    width: 102PX;
    float: left
}

.list-product {
    min-height: 448PX;
    position: relative;
    border: 1PX solid #cdcdcd;
    margin-top: 30PX
}

.list-productimg {
    width: 100%
}

.list-producticon {
    position: absolute;
    top: 15PX;
    right: -7PX;
    z-index: 2;
    width: 95PX!important
}

.list-othernews {
    margin-top: 30PX;
    padding-left: 15PX
}

.list-othernews ul {
    padding-left: 15PX
}

.list-othernews ul li {
    width: 100%
}

.list-othernews h3 {
    font-weight: 700;
    margin-top: 0;
    font-size: 14PX;
    color: #263997;
    line-height: 20PX
}

.list-othernews h3:hover {
    color: #008e97
}

.dangki-hotline {
    overflow: hidden
}

.dangki-hotlineimg {
    margin-top: 3PX;
    margin-right: 5%;
    float: left
}

.hotline-deproduct {
    min-width: 200PX;
    float: left
}

.hotline-deproduct p {
    margin-bottom: 0;
    color: #f58220
}

.hotline-deproduct img {
    margin-right: 5PX;
    float: left;
    margin-top: 3PX
}

.hotline-deproduct .sdthotline-deproduct {
    color: #008e97;
    font-size: 24PX;
    font-family: bold;
    font-weight: 700
}

.deproduct-main h1 {
    font-size: 18PX;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 15PX;
    line-height: 22PX
}

.deproduct-main table {
    border: 1PX solid #cdcdcd;
    width: 100%;
    margin-bottom: 15PX
}

.deproduct-main table tr td {
    border: 1PX solid #cdcdcd;
    padding: 10PX
}

.deproduct-main table tr td:last-child {
    font-weight: 700
}

.deproduct-imgage {
    margin-bottom: 30PX
}

.deproduct-main {
    overflow: hidden;
    margin-bottom: 30PX
}

.left-home #cate_t {
    overflow: hidden;
    border-bottom: 1PX solid #cdcdcd;
    margin-bottom: 30PX
}

.right-height30 {
    height: 20PX
}

.detailproduct-title {
    background: #f58220;
    color: #fff;
    text-transform: uppercase;
    font-size: 18PX;
    font-weight: 700;
    line-height: 45PX;
    text-align: center
}

.detailproduct-text {
    border: 1PX solid #008e97;
    border-top: none;
    background: #feeded;
    padding: 30PX;
    margin-bottom: 20PX
}

.detailproduct-text b,.detailproduct-text strong {
    color: #008e97
}

.ul-otherproduct li {
    padding-left: 15PX;
    background: url(/img/gw/cham-xanh.png) no-repeat left 0 top 5PX;
    list-style: none
}

.ul-otherproduct li h3 {
    color: #f58220;
    font-size: 14PX
}

.ul-otherproduct li:hover {
    background: url(/img/gw/cham-do.png) no-repeat left 0 top 5PX
}

.ul-otherproduct li:hover h3 {
    color: #008e97
}

.mutiprodu-title h2 {
    color: #000;
    text-transform: uppercase;
    font-size: 18PX;
    font-weight: 700;
    margin: 0
}

.mutidangky {
    padding-top: 40PX;
    border-top: 1PX solid #cdcdcd;
    overflow: hidden;
    padding-bottom: 40PX
}

.dangky-form h2 {
    color: #008e97;
    font-size: 24PX;
    text-align: center;
    margin-top: 0;
    margin-bottom: 40PX;
    text-transform: uppercase;
    font-weight: 700
}

.dangky-form input[type=email],.dangky-form input[type=text] {
    height: 33PX;
    width: 100%;
    border-radius: 3PX;
    border: 1PX solid #cdcdcd;
    margin-bottom: 10PX;
    box-shadow: inset 0 0 6PX 0 hsla(0,0%,80%,.8196078431372549)
}

.dangky-form input[type=submit] {
    border: none;
    float: right;
    background: #f58220;
    color: #ff0;
    padding: 5PX 30PX;
    font-size: 16PX;
    border-radius: 5PX;
    box-shadow: 0 4PX 0 #061469
}

.dangky-form input[type=submit]:hover {
    background: #ff0;
    color: #f58220;
    box-shadow: 0 4PX 0 #cccc10
}

.dangky-form label {
    width: 100%;
    font-weight: 500
}

.dangky-form textarea {
    height: 150PX;
    width: 100%;
    border-radius: 3PX;
    border: 1PX solid #cdcdcd;
    margin-bottom: 15PX;
    box-shadow: inset 0 0 6PX 0 hsla(0,0%,80%,.8196078431372549)
}

#lienhe {
    padding-top: 20PX;
    margin-left: -15PX;
    margin-right: -15PX
}

.form_lh label {
    line-height: 30PX;
    width: 100%
}

.form_lh input[type=text] {
    width: 100%;
    height: 30PX;
    border: 1PX solid #cdcdcd;
    margin-bottom: 10PX
}

.form_lh input[type=submit] {
    border: none;
    font-size: 14PX;
    padding: 5PX 18PX;
    border-radius: 4PX;
    color: #fff;
    font-weight: 600;
    background: #00f;
    box-shadow: 1PX 1PX 1PX rgba(0,0,0,.67);
    float: right;
    margin-top: 20PX;
    margin-left: 20PX
}

.form_lh ul li {
    list-style: none
}

.lien_he h4 {
    padding-bottom: 40PX;
    line-height: 23PX;
    font-size: 16PX
}

.lien_he h2 {
    color: #00f;
    font-size: 20PX;
    text-transform: uppercase;
    font-family: myriadbold;
    margin-bottom: 15PX;
    font-weight: 700
}

.lien_he p {
    line-height: 25PX;
    font-size: 14PX
}

.form_lh h4 {
    padding-bottom: 10PX;
    line-height: 23PX;
    font-size: 16PX
}

.form_lh textarea {
    width: 100%;
    height: 100PX;
    border: 1PX solid #cdcdcd;
    margin-bottom: 25PX
}

.ma_bao_ve input[type=text] {
    width: 30%;
    height: 30PX;
    border: 1PX solid #cdcdcd;
    margin-bottom: 10PX;
    float: left
}

.ma_bao_ve p {
    text-align: center;
    float: right;
    width: 65PX;
    line-height: 30PX;
    border: 1PX solid #cdcdcd
}

.ma_bao_ve label {
    line-height: 30PX;
    width: 80PX;
    float: left
}

.newtow-pagetow li .date-new {
    margin-bottom: 0!important
}

.list-video {
    margin-top: 25PX;
    position: relative;
    min-height: 220PX
}

.list-video h3 {
    font-size: 14PX;
    line-height: 20PX;
    margin-bottom: 0;
    color: #000
}

.img-listvideo {
    width: 100%
}

.play {
    left: 40%;
    opacity: .7;
    width: 50PX;
    top: 25%;
    position: absolute
}

.h2-videoother {
    font-size: 16PX;
    padding-left: 15PX;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0
}

.h1_videotitle {
    font-size: 24PX;
    margin-top: 40PX;
    margin-bottom: 0
}

.chuacodl {
    font-style: italic;
    margin-top: 20PX
}

.left-home,.right-home {
    overflow: hidden
}

.banner-homeright img {
    width: 100%
}

.cate-chuyenmuc {
    margin-bottom: 20PX
}

.btn-dangky {
    float: right;
    background: none;
    border: none;
    margin-top: 39PX
}

.formhomedangky label {
    font-weight: 500;
    float: none;
    width: 100%
}

.ullidangki {
    overflow: hidden
}

.ullidangki li {
    list-style: none
}

.formhomedangky input[type=text] {
    margin-bottom: 10PX;
    float: none;
    width: 100%;
    margin-right: 0
}

.formhomedangky input[type=submit] {
    background: #008e97;
    margin-top: 20PX;
    border: none;
    padding: 7PX 20PX;
    color: #fff
}

.formhomedangky select {
    height: 27PX;
    margin-bottom: 10PX;
    float: left;
    width: 31%;
    margin-right: 2%
}

.formhomedangky input[type=radio] {
    margin-left: 15PX;
    margin-right: 5PX
}

.formhomedangky1 {
    margin-top: 15PX;
    overflow: hidden
}

.formhomedangky1 textarea {
    width: 78%;
    margin-left: 2%;
    height: 100PX
}

.formhomedangky1 label {
    font-weight: 500;
    float: left;
    width: 20%;
    margin-bottom: 0;
    line-height: 100PX
}

.modal-title {
    text-transform: uppercase;
    font-size: 17PX;
    font-weight: 700;
    text-align: center
}

.btn-top {
    background-image: url(/img/gw/btn_top.png);
    background-repeat: no-repeat;
    border: medium none;
    bottom: 10PX;
    cursor: pointer;
    display: none;
    height: 53PX;
    outline: medium none;
    padding: 0;
    position: fixed;
    right: 10PX;
    width: 53PX;
    z-index: 9999;
    transition: all .5s ease-in-out
}

.btn-top:hover {
    background-image: url(/img/gw/btn_top-h.png);
    transition: all .5s ease-in-out
}

.google_plusone_iframe_widget {
    width: 40PX!important
}

#style-2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6PX rgba(0,0,0,.3);
    border-radius: 10PX;
    background-color: #f5f5f5
}

#style-2::-webkit-scrollbar {
    width: 6PX;
    background-color: #f5f5f5
}

#style-2::-webkit-scrollbar-thumb {
    border-radius: 10PX;
    -webkit-box-shadow: inset 0 0 6PX rgba(0,0,0,.3);
    background-color: rgba(124,118,118,.25098039215686274)
}

.call-mobile {
    background: #e5332d;
    position: fixed;
    bottom: 70PX;
    height: 40PX;
    line-height: 40PX;
    padding: 0 20PX 0 0;
    border-radius: 40PX;
    color: #fff;
    left: 10PX;
    z-index: 6;
    width: 200PX;
    text-align: center
}

.call-mobile img {
    background-color: #ad0202;
    border-radius: 39PX;
    float: left;
    width: 39PX;
    height: 39PX;
    margin-right: 10PX
}

.call-mobile a {
    color: #fff;
    font-size: 18PX;
    font-weight: 700;
    text-decoration: none;
    white-space: nowrap
}

.call-mobile1 {
    background: #339af2;
    position: fixed;
    bottom: 60PX;
    height: 40PX;
    line-height: 40PX;
    padding: 0 20PX 0 0;
    border-radius: 40PX;
    color: #fff;
    left: 10PX;
    z-index: 6;
    width: 200PX;
    text-align: center
}

.call-mobile1 img {
    background-color: #1f8eee;
    border-radius: 39PX;
    float: left;
    width: 39PX;
    height: 39PX;
    margin-right: 10PX
}

.call-mobile1 a {
    color: #fff;
    font-size: 18PX;
    font-weight: 700;
    text-decoration: none;
    white-space: nowrap
}

.table-gioithieu tr td {
    vertical-align: top
}

#warning {
    font-size: 13PX;
    color: #e32c22;
    padding-left: 0;
    font-style: normal;
    display: none
}

.popbanhluan input[type=email],.popbanhluan input[type=text] {
    border-radius: 4PX;
    margin-bottom: 10PX;
    width: 100%;
    height: 30PX;
    margin-top: 5PX;
    border: 1PX solid #ccc;
    padding-left: 15PX
}

.popbanhluan input[type=submit] {
    display: inline-block;
    padding: 6PX 12PX;
    margin-bottom: 0;
    font-size: 14PX;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1PX solid transparent;
    border-radius: 4PX;
    color: #fff;
    background-color: #e5332d;
    margin-bottom: 30PX;
    margin-top: 15PX
}

.binhluan-web {
    background: #008e97;
    padding: 10PX 15PX;
    margin-top: 20PX;
    margin-bottom: 15PX
}

.binhluan-web label,.binhluan-web p {
    color: #fff
}

.binhluan-web #addcart1 {
    width: 100%
}

.binhluan-web textarea {
    width: 100%;
    height: 100PX;
    margin-top: 5PX;
    margin-bottom: 10PX;
    padding-left: 15PX;
    padding-top: 10PX
}

.addcart {
    padding: 10PX 15PX;
    background: #e5332d;
    text-transform: uppercase;
    color: #fff;
    margin: 10PX 0;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    text-align: center
}

.time {
    padding-left: 15PX;
    color: #b8c0c1;
    position: relative
}

.time:before {
    content: "";
    position: absolute;
    display: block;
    top: 5PX;
    left: 5PX;
    width: 3PX;
    height: 3PX;
    background-color: #ccc;
    border-radius: 50%
}

.onebl {
    padding-bottom: 10PX;
    border-bottom: 1PX dashed hsla(0,0%,80.4%,.4196078431372549);
    margin-bottom: 10PX
}

.xemthem a {
    text-decoration: underline;
    font-weight: 700;
    color: red
}

.xemthem {
    margin-top: 10PX;
    text-align: right;
    font-style: italic
}

.xemthemhome {
    font-size: 13PX;
    text-align: right;
    margin-top: 10PX;
    font-weight: 700;
    text-decoration: underline
}

.xemthemhome a {
    color: red
}

.widget {
    margin-top: 10PX;
    float: left;
    width: 100%
}

.widget-highlight-job .widget-wrap {
    max-height: 320PX;
    overflow-x: hidden;
    overflow-y: scroll
}

.widget-content {
    padding-top: 15PX
}

.widget-highlight-job .widget-content>ul {
    column-count: 2;
    -moz-column-count: 2;
    -webkit-column-count: 2
}

.widget-content>ul>li {
    padding: 10PX 5PX;
    list-style: none;
    text-decoration: none
}

.widget-content>ul>li>a>h3 {
    font-size: 13PX;
    font-weight: 400
}

.widget-title .view-all,.widget-title .view-more {
    text-transform: none;
    font-size: 10PX;
    display: block;
    float: right;
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 10PX;
    color: #444
}

.widget-title .view-all:after,.widget-title .view-more:after {
    content: "\f138";
    font-family: fontawesome;
    font-size: 10PX;
    margin-left: 5PX
}

.widget-content>ul>li>a>h3 {
    font-size: 14PX;
    font-weight: 500;
    line-height: 21PX;
    color: #333;
    max-height: 42PX;
    overflow: hidden;
    white-space: normal!important;
    margin-top: 0
}

.widget-content>ul>li>a>h4 {
    font-size: 10pt;
    font-weight: 500;
    display: inline-block;
    margin-top: 5PX;
    margin-right: 10PX;
    color: #0158b5
}

.widget-content>ul>li>a>h4>span {
    font-weight: 300;
    padding-right: 5PX;
    color: #444;
    font-size: 11PX
}

.widget-content>ul>li {
    border-bottom: 1PX dashed #ddd;
    padding: 5PX 5PX;
    padding-top: 10PX
}

.widget-content>ul>li>a {
    color: #666
}

.widget-content li:hover {
    background-color: #eee
}

.widget-content .caption {
    font-weight: 500;
    color: #777;
    background-color: #eee;
    font-size: 8pt;
    text-transform: uppercase;
    height: 25PX;
    line-height: 25PX;
    overflow: hidden
}

.caption span:last-child {
    text-align: right
}

div,li {
    list-style: none;
    page-break-inside: avoid;
    -moz-column-break-inside: avoid;
    break-inside: avoid
}

.banner_home {
    margin-bottom: 20PX
}

.clear {
    clear: both
}

::-webkit-scrollbar {
    width: 8PX
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6PX rgba(0,0,0,.3);
    border-radius: 8PX
}

::-webkit-scrollbar-thumb {
    border-radius: 8PX;
    background: #008e97;
    -webkit-box-shadow: inset 0 0 6PX rgba(0,0,0,.5)
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #008e97
}

.nhatban24h {
    color: #333;
    font-size: 16PX;
    line-height: 22PX;
    display: inline-block;
    background: #dbedf9;
    border: 1PX solid #c7e4f4;
    border-radius: 4PX;
    padding: 1.5%;
    margin-bottom: 15PX
}

.nhatban24h a {
    font-weight: 400;
    text-decoration: none;
    font-size: 16PX;
    color: #167ac6
}

.details p {
    font-size: 16PX
}

.detailproduct-text img {
    height: auto!important
}

.img-news {
    width: 100%;
    float: left;
    margin-top: 15PX;
    margin-bottom: 25PX
}

.img-news img {
    width: 100%;
    float: left
}

.info_support {
    width: 100%;
    float: left;
    border: 2PX dashed #ccc;
    padding: 15PX;
    margin: 15PX 0;
    background: #f3f3f3
}

.info_support img {
    width: 30%;
    float: left;
    margin-right: 30PX;
    border: 3PX solid #f58220;
    border-radius: 100%
}

.info_support .content_support h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18PX;
    color: #008e97;
    margin-bottom: 15PX
}

.info_support .content_support p {
    font-style: italic;
    color: #121212;
    line-height: 25PX;
    font-size: 16PX
}

.info_support .content_support a {
    background: #f58220;
    box-shadow: 3PX 4PX 25PX rgba(198,58,149,.5019607843137255);
    text-transform: uppercase;
    color: #fff;
    text-shadow: 0 1PX 3PX #666;
    font-size: 16PX;
    font-weight: 500;
    padding: 10PX 35PX;
    border-radius: 50PX;
    margin-top: 10PX;
    float: right
}

#zalo-me {
    position: fixed;
    right: 10PX;
    bottom: 70PX;
    z-index: 9999999999!important
}

#zalo-me img {
    width: 55PX
}

.page {
    position: absolute!important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f5
}

.nav-bar {
    background: linear-gradient(90deg,#500cfd,#9e9ce7);
    height: 13.333vw
}

.van-nav-bar {
    line-height: 6.667vw
}

 .van-nav-bar__title {
    max-width: 60%;
    margin: 0 auto;
    color: #fff;
    font-size: 4.667vw
}

 .van-nav-bar__content {
    height: 13.333vw
}

.van-sidebar {
    width: 24vw
}

.convention-hall {
    display: flex;
    flex-direction: column;
    bottom: 2.667vw;
    background: #f2f2f5
}

 .van-tab {
    font-size: 4vw;
    line-height: 13.333vw;
    font-weight: 700
}

 .van-tabs__line {
    background-color: #500cfd
}

 .van-tabs--line .van-tabs__wrap {
    height: 13.333vw
}

 .van-tabs__wrap--scrollable .van-tab {
    padding: 0 3.067vw
}

.card {
    background-color: #8a637d;
    padding: .625rem;
    width: 95%;
    color: #fff;
    font-size: .8125rem;
    margin: .625rem auto;
    border-radius: .375rem
}

 .van-row--flex {
    height: 10.667vw;
    line-height: 10.667vw
}

.rig-box {
    width: 95%;
    margin: .625rem auto
}

.rig-title {
    color: #0bdab0;
    font-size: 1.125rem
}

.rig-content {
    font-size: 2.667vw
}

.address {
    width: 94%;
    margin: 0 auto
}

.van-cell {
    padding: 4vw 2.933vw;
    font-size: 4vw;
    line-height: 4vw
}

.pageone-title h2 {
    margin: 0;
    font-size: 2.267vw;
    background: #fff;
    display: inline-block;
    padding-right: 1.333vw;
    font-size: 15PX;
    display: block;
    text-transform: uppercase;
    font-weight: 700
}

.container {
    background-color: #fff;
    z-index: 1!important
}

.pageone-tab .nav-tabs>li {
    width: 50%!important
}

.col-lg-1,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-10,.col-lg-11,.col-lg-12,.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12,.col-sm-1,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-10,.col-sm-11,.col-sm-12,.col-xs-1,.col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9,.col-xs-10,.col-xs-11,.col-xs-12 {
    position: relative;
    min-height: 1px;
    padding-right: 2vw;
    padding-left: 2vw
}

.col-xs-1,.col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9,.col-xs-10,.col-xs-11,.col-xs-12 {
    float: left
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-tabs {
    border-bottom: 1px solid #ddd
}

table {
    border-spacing: 0;
    border-collapse: collapse
}

.tab-content {
    display: block
}

.right {
    margin-top: 1.333vw
}

 .van-grid-item__content--center {
    border-radius: 2vw;
    padding: 0;
    height: auto
}

 .van-image__img {
    border-radius: 1.333vw;
    padding: 2vw
}

.rig-name {
    width: 100%;
    height: 8vw;
    line-height: 8vw;
    margin-top: 1.333vw;
    background-color: #f7f7f7;
    border-radius: 0 0 2vw 2vw;
    font-size: 2vw;
    padding-left: 1.333vw
}
</style>