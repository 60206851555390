<template>
  <div>
    <div class="header-box">
      <div class="logo-box">
        <img src="/img/sy/a5.jpg" />
      </div>
      <div class="search-box">
        <input placeholder="Tìm kiếm" @keyup="searchlst()" v-model="searchtxt"  />
      </div>
      <div style="clear: both"></div>
    </div>
    <div class="context-box">
      <div class="hot-box">
        <div class="hot-overflow-box">
          <div class="hot-overflow-context" :style="'width:'+(hotlst.length * 46)+'vw'">
            <div class="hot-overflow-context-item" style="float: left;" v-for="(item,index) in hotlst" :key="index" @click="toInfo(item.id)">
              <div style="width: 100%;height: 100%;">
                <div class="hot-overflow-context-item-box">
                  <div style="width: 100%;position: relative;">
                    <div style="width: 100%;">
                      <img
                        style="width: 42vw;height: 29vw;object-fit: cover;"
                        :src="item.header_img"
                      />
                    </div>
                    <div
                      style="position: absolute;border: 1px solid red;background-color: #bf1d28;top: 5px;left: 5px;color: #fff;padding: 4px 7px;border-radius: 4px;font-size: 8px;text-align: center;"
                    >
                      Nổi bật
                    </div>
                  </div>
                  <ul style="font-size: 14px;">
                    <li>
                      {{ item.nationality }}
                    </li>
                    <li>
                      Ngành nghề:
                    </li>
                    <li>
                      {{ item.industry }}
                    </li>
                    <li>
                      Mức lương :
                    </li>
                    <li>
                      <div style="overflow-wrap: break-word;height: 40px;overflow: hidden;">{{ item.salary }}</div>
                    </li>
                  </ul>
                  <div style="margin-top: 6px;overflow: hidden;width: 46vw;">
                    <div class="hot-overflow-context-item-icon-txt">
                      <div
                        class="hot-overflow-context-item-icon"
                        style=" min-height: 16px !important;
  width: 16px !important;
  height: 16px !important;
  background-position: -20px -46px;float: left;"
                      />
                      {{ showtiem(item.time) }}
                    </div>
                    <div class="hot-overflow-context-item-icon-txt">
                      <div
                        class="hot-overflow-context-item-icon"
                        style=" min-height: 16px !important;
  width: 16px !important;
  height: 16px !important;
  background-position: -45PX -46PX;;float: left;"
                      />
                      {{ item.address }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 15px;;padding: 15px 0;margin-bottom: 60px;">
        <div v-for="(item,index) in lst" :key="index"  @click="toInfo(item.id)"
          style="width: 100%;margin: 0px auto 0px auto;border-bottom: 1px solid #ecedef;padding:10px 10px;background-color: #fff;"
        >
          <div style="float: left;width: 31%;position: relative;">
            <img
              style="width: 29vw;height: 19.5vw;object-fit: cover;"
              :src="item.header_img"
            />
            <div style="position: absolute;background-color: #ff641e;color: #fff;font-size: 8px;padding: 3px 5px;border-radius: 4px;top: 8px;left: 8px;">VIP S</div>
          </div>
          <div style="float: left;width: 69%;padding: 0 10px;">
            <ul style="font-size: 14px;">
                    <li>
                      {{ item.nationality }}
                    </li>
                    <li>
                      Ngành nghề:
                    </li>
                    <li>
                      {{ item.industry }}
                    </li>
                    <li>
                      Mức lương :
                    </li>
                    <li>
                      <div style="overflow-wrap: break-word;height: 40px;overflow: hidden;">{{ item.salary }}</div>
                    </li>
                  </ul>
                  <div style="margin-top: 6px;overflow: hidden;width: 60vw;">
                    <div class="hot-overflow-context-item-icon-txt">
                      <div
                        class="hot-overflow-context-item-icon"
                        style=" min-height: 16px !important;
  width: 16px !important;
  height: 16px !important;
  background-position: -20px -46px;float: left;"
                      />
                      {{ showtiem(item.time) }}
                    </div>
                    <div class="hot-overflow-context-item-icon-txt">
                      <div
                        class="hot-overflow-context-item-icon"
                        style=" min-height: 16px !important;
  width: 16px !important;
  height: 16px !important;
  background-position: -45PX -46PX;;float: left;"
                      />
                      {{ item.address }}
                    </div>
                  </div>
          </div>
          <div style="clear: both;" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home1",
  data() {
    return {
      baselst:[],
      hotlst: [],
      lst: [],
      searchtxt:''
    };
  },
  mounted() {
    this.Init();
  },
  methods: {
    Init() {
      this.getData();
    },
    getData() {
      this.hotlst = [];
      this.lst = [];
      this.baselst =[];
      this.$http({
        method: "get",
        url: "get_home1_data",
      }).then((res) => {
        res.data.forEach((item) => {
          if (item.top === 1) {
            this.hotlst.push(item);
          } else {
            this.lst.push(item);
            this.baselst.push(item)
          }
        });
      });
    },
    toInfo(id){
      this.$router.push({ path: '/HomeInfo?id='+ id })
    },
    showtiem(val) {
      let result = val;
      switch (val) {
        case 0:
          result = this.$t("timetxt.v0");
          break;
        case 1:
          result = this.$t("timetxt.v1");
          break;
        case 2:
          result = this.$t("timetxt.v2");
          break;
        case 3:
          result = this.$t("timetxt.v3");
          break;
        case 4:
          result = this.$t("timetxt.v4");
          break;
      }
      return result;
    },
    searchlst(){
      if(this.searchtxt){
        if(this.searchtxt.length > 0){
          this.lst = []
          this.baselst.forEach(item => {
            if(item.address.indexOf(this.searchtxt) != -1 || item.industry.indexOf(this.searchtxt) != -1 || item.salary.indexOf(this.searchtxt) != -1 ){
              this.lst.push(item)
            }
          });
        }
        else{
          this.lst = JSON.parse(JSON.stringify(this.baselst))
        }
      }
      else{
        this.lst = JSON.parse(JSON.stringify(this.baselst))
      }
    }
  },
};
</script>

<style scoped>
.header-box {
  width: 100%;
  height: 13.33vw;
  background-color: #fff;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999;
}
.header-box .logo-box {
  float: left;
  height: 100%;
  line-height: 4;
}
.header-box .logo-box img {
  height: 100%;
  margin-left: 10px;
}
.header-box .search-box {
  float: right;
  height: 100%;
  line-height: 3;
}
.header-box .search-box input {
  margin-top: 2vw;
  float: right;
  margin-right: 10px;
  width: 35vw;
  line-height: 8.33vw;
  border-radius: 5vw;
  border: 1px solid #ecedef;
  text-indent: 12px;
  background-image: url(/img/sy/ss.png);
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position: 22vw center;
}
.context-box {
  margin-top: 16.33vw;
}
.hot-box {
  height: 64vw;
  padding: 16px 0;
  background: linear-gradient(
    180deg,
    #ffe2c6,
    rgba(255, 241, 235, 0.4) 53.12%,
    #fff 88.98%,
    #fff
  );
  padding: 0 2vw;
}
.hot-overflow-box {
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #fff;
}
.hot-overflow-context {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}
.hot-overflow-context-item {
  padding: 10px;
  width: 46vw;
  height: 64vw;
} 
.hot-overflow-context-item-box {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 5px;
}
.hot-overflow-context-item-icon {
  background: url("/img/sy/common.png");
  background-repeat: no-repeat;
}
.hot-overflow-context-item-icon-txt {
  font-size: 0.65rem;
  color: #717375;
  float: left;
  line-height: 1.6;
}
</style>
